
import { device } from '../../shared/device'
import styled from 'styled-components'
import { TwitterFollowButton } from "react-twitter-embed";
import PageDisplayTest from '../components/PageDisplayTest'

import CommentList from '../components/Comment/CommentList'
import { AiOutlineCopyrightCircle } from "react-icons/ai";

const TwitterPadding = styled.div `
padding: 2rem 0rem;
`

const CommentPadding = styled.div `
margin: 3rem 3rem 0rem 3rem;
`
const Mainpage = styled.div`
  background: #1f1f1f;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex-direction: column;
  
  @media ${device.laptop} {
    max-width: 800px;
  }

  @media ${device.desktop} {
    max-width: 2560px;
  }
  

  // Switch to rows on large devices
  @media ${device.laptop} {
    flex-direction:column;
  }
`;

const CopyWriteDiv = styled.div`
  text-align: center;
  color: white;
  padding: 1rem;
`;





const ComicPages = () => {
 
    return (
      <Mainpage>
        <PageDisplayTest />
        <TwitterPadding>
          <TwitterFollowButton screenName={"Matriarch_comic"} />
        </TwitterPadding>
        <CommentList />
        <CommentPadding />
        <CopyWriteDiv>
          {" "}
          <AiOutlineCopyrightCircle color="white" /> 2019 Harper Hall
        </CopyWriteDiv>
      </Mainpage>
    );
}

export default ComicPages