import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../context/auth-context";
import { useHttpClient } from '../../../hooks/http-hook'
import ErrorModal from '../../Modal/ErrorModal';
import LoadingSpinner from "../../Modal/LoadingSpinner";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import { IconContext } from 'react-icons'
import { AiOutlineClose, AiOutlineMenu} from "react-icons/ai"
import { GoBookmark } from "react-icons/go";
import { GiScrollQuill, GiAcorn } from "react-icons/gi";
import './Sidebar.css'

const SidebarLink = styled(Link)`
  display: flex;
  color: #e1e9fc;
  align-items: center;
  padding: 10px;
  list-style: none;
  height: auto;
  text-decoration: none;
  font-size: 18px;
  &:hover {
    background: #252831;
    border-left: 8px solid #987501;
    cursor: pointer;
  }
`;
const DivCenter = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  position: fixed;
  margin-top: 50vh;
  justify-content: center;
`;

const SidebarButton = styled.button`
  display: flex;
  color: #e1e9fc;
  background-color: #15171c ;
  border: none;
  justify-content: space-between;
  align-items: center;
  padding: 10px 100px 10px 10px;
  list-style: none;
  height: auto;
  text-decoration: none;
  font-size: 18px;
  &:hover {
    background: #252831;
    border-left: 8px solid #987501;
    cursor: pointer;
  }
`;

const ButtonP = styled.p`
  padding: 0px 15px;
  white-space: nowrap;
`;

const Nav = styled.div`
  ${'' /* background: #11110c; */}
  height: 80px;
  width: 250px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 1001;
`;

const MenuIcon = styled.img`
  width: 15rem;
  padding-top: 6.5rem;
  margin-left: 60px;
  height: auto;
  position: absolute;
  z-index: 1001;
`;

const NavIcon = styled(Link)`
  color: #0080ff80c;
  margin-left: 2rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 1002;
`;

const SidebarNav = styled.nav`
  background: #15171c;
  width: 225px;
  height: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  overflow: auto;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 1002;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const Sidebar = () => {

const [latestPage, setLatestPage] = useState()

const { isLoading, error, sendRequest, clearError } = useHttpClient();
  useEffect(() => {
    const fetchPage = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/pages"
        );
        setLatestPage(responseData.pages.length);
      } catch (err) {}
    };
    fetchPage();
  }, [sendRequest]);

  const auth = useContext(AuthContext);

  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <DivCenter>
          <LoadingSpinner />
        </DivCenter>
      )}
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav>
          <IconContext.Provider
            value={{
              style: {
                margin: "-1.4rem 1rem",
                color: "white",
                position: "fixed",
                zIndex: "1001",
              },
              size: "2.5rem",
            }}
          >
            <div>
              {" "}
              <AiOutlineMenu onClick={showSidebar} />{" "}
            </div>
          </IconContext.Provider>
          <MenuIcon
            src={process.env.PUBLIC_URL + "/Matriarch_Nav_Icon.png"}
            onClick={showSidebar}
          />
        </Nav>
        <SidebarNav sidebar={sidebar}>
          <SidebarWrap>
            <NavIcon to="#">
              <AiOutlineClose onClick={showSidebar} />
            </NavIcon>
            <SidebarLink to="/Home">
              <GiAcorn />
              <ButtonP className="raleway__font">Home</ButtonP>
            </SidebarLink>
            <SidebarLink to={`/pages/${latestPage}`}>
              <GoBookmark />
              <ButtonP className="raleway__font">Latest Page</ButtonP>
            </SidebarLink>
            {SidebarData.map((item, index) => {
              return <SubMenu item={item} key={index} />;
            })}
            {!auth.isLoggedIn && (
              <SidebarLink to="/Login">
                {" "}
                <GiScrollQuill />
                <ButtonP className="raleway__font">Login/Sign Up</ButtonP>
              </SidebarLink>
            )}
            {auth.isLoggedIn && (
              <SidebarButton onClick={auth.logout}>
                <GiScrollQuill />
                <ButtonP className="raleway__font">Log Out</ButtonP>
              </SidebarButton>
            )}
            
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </>
  );
};

export default Sidebar;
