import React, { useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { useForm } from "../../../shared/hooks/form-hook";
import { AuthContext } from "../../../shared/context/auth-context";
import ErrorModal from "../../../shared/components/Modal/ErrorModal";
import LoadingSpinner from "../../../shared/components/Modal/LoadingSpinner";
import { VALIDATOR_REQUIRE } from "../../../shared/components/util/validators";
import Button from "../../../shared/components/FormElements/Button";
import ModalForm from "../../../shared/components/Modal/ModalForm";
import Input from "../../../shared/components/FormElements/Input";
import "./Comment.css";
import { useHttpClient } from "../../../shared/hooks/http-hook";

const DivCenter = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
`;

const ContainerDiv = styled.div`
  display: flex;
  margin-left: 10%;
  justify-content: center;
  align-content: flex-start;
`;
const ReplyDiv = styled.div`
  ${
    "" /* background: #3e3e3e;
  box-shadow: 3px 3px 17px #000000;
  border-radius: 10px; */
  }
  padding: 4px 5px;
  color: #d6d6d6;
  font-size: 20px;
  width: auto;
  height: auto;
  overflow: auto;
`;

const ReplyLi = styled.li`
  margin: -3rem 0rem 3rem 0rem;
`;
const ModalContainer = styled.div`
  width: 15vh;
  height: 5vh;
  display: flex;
  justify-content: center;
`;
const ModalDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
`;
const InputDiv = styled.div`
  width: 90%;
`;
const ModalButtonDiv = styled.div`
  display: flex;
  justify-content: center;
`;
const ButtonDiv = styled.div`
  margin: 2px, px, 0px, 0px;
  font-size: 14px;
`;

const ReplyItem = (props) => {
  const auth = useContext(AuthContext);
  const page = useParams.page;
  const [editingReplyId, setEditingReplyId] = useState();
  let thisReplyId = props.id;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedReply, setLoadedReply] = useState();
  const [formState, inputHandler] = useForm(
    {
      reply: {
        value: "",
        isValid: false,
      },
      aReply: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const showEditWarningHandler = (event) => {
    event.preventDefault();
    const fetchReplys = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/pages/getReply/${thisReplyId}`
        );
        setLoadedReply(responseData.reply);
      } catch (err) {}
    };
    fetchReplys();
    setShowConfirmModal(true);
  };

  const submitEditHandler = async (event) => {
    event.preventDefault();
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/pages/updateReply/${thisReplyId}`,
        "PATCH",
        JSON.stringify({
          aReply: formState.inputs.aReply.value,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
      props.onEdit(props.id);
      console.log(thisReplyId)
    } catch (err) {}
    setShowConfirmModal(false);
  };

  const cancelDeleteHandler = (event ) => {
    event.preventDefault();
    setShowConfirmModal(false);
  };

  const confirmDeleteHandler = async (event) => {
    event.preventDefault();
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/pages/deleteReply/${thisReplyId}`,
        "DELETE",
        null,
        { Authorization: "Bearer " + auth.token }
      );
      props.onDelete(props.id);
    } catch (err) {}
    setShowConfirmModal(false);
  };

 

  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <DivCenter>
          <LoadingSpinner asOverlay />
        </DivCenter>
      )}
      {!isLoading && loadedReply && (
        <ModalContainer>
          <ModalForm
            onSubmit={submitEditHandler}
            show={showConfirmModal}
            onCancel={cancelDeleteHandler}
          >
            <ModalDiv>
              <InputDiv>
                {" "}
                <Input
                  id="aReply"
                  element="textarea"
                  validators={[VALIDATOR_REQUIRE()]}
                  errorText="Please enter a valid Reply"
                  onInput={inputHandler}
                  value={loadedReply.aReply}
                />
                <ModalButtonDiv>
                  <Button type="submit" disabled={formState.isValid}>
                    Submit
                  </Button>
                  <Button onClick={cancelDeleteHandler}>Cancel </Button>
                  <Button onClick={confirmDeleteHandler}>Delete </Button>
                </ModalButtonDiv>
              </InputDiv>
            </ModalDiv>
          </ModalForm>
        </ModalContainer>
      )}
         
      <ContainerDiv>
        <ReplyLi className="li__styles">
          <ReplyDiv key={props._id}>
            <p className="bilbo__font">{props.creator}</p>
            <p className="raleway__font">{props.aReply}</p>
          </ReplyDiv>
          <ButtonDiv>
            <Button danger onClick={showEditWarningHandler}>
              Edit
            </Button>
          </ButtonDiv>
        </ReplyLi>
      </ContainerDiv>
    </>
  );
};

export default ReplyItem;
