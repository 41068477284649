import React, { useState, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from "styled-components";
import { useForm } from "../../../shared/hooks/form-hook";
import { AuthContext } from '../../../shared/context/auth-context'
import ErrorModal from "../../../shared/components/Modal/ErrorModal";
import LoadingSpinner from "../../../shared/components/Modal/LoadingSpinner";
import { VALIDATOR_REQUIRE } from "../../../shared/components/util/validators";
import Button from '../../../shared/components/FormElements/Button'
import ModalForm from '../../../shared/components/Modal/ModalForm';
import Input from '../../../shared/components/FormElements/Input'
import './Comment.css'
import { useHttpClient } from "../../../shared/hooks/http-hook";

const DivCenter = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
`;

const ContainerDiv = styled.div `
display: flex;
justify-content: center;
align-content: flex-start;
`
const CommentDiv = styled.div`
  ${'' /* background: #3e3e3e;
  box-shadow: 3px 3px 17px #000000;
  border-radius: 10px; */}
  padding: 4px 5px;
  color: #d6d6d6;
  font-size: 20px;
  width: auto;
  height: auto;
  overflow: auto;
`;
const ReplyDiv = styled.div`
  ${
    "" /* background: #3e3e3e;
  box-shadow: 3px 3px 17px #000000;
  border-radius: 10px; */
  }
  padding: 4px 5px;
  color: #d6d6d6;
  font-size: 20px;
  width: auto;
  height: auto;
  overflow: auto;
`;
const CommentLi = styled.li `
margin: -3rem 0rem 3rem 0rem;

`;
const ModalContainer = styled.div `
width: 15vh;
height: 5vh;
display: flex;
justify-content: center;
`
const ModalDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  
`;
const InputDiv = styled.div `
width: 90%
`
const ModalButtonDiv = styled.div`
  display: flex;
  justify-content: center;
`;
const ButtonDiv = styled.div`
margin: 2px;
font-size: 14px;
`




const CommentItem = props => {

const auth = useContext(AuthContext)
const [editingCommentId, setEditingCommentId] = useState()
let thisCommentId = props.id
const { isLoading, error, sendRequest, clearError} = useHttpClient();
const [loadedComment, setLoadedComment] = useState()
const [formState, inputHandler] = useForm(
    {
      aReply: {
        value: "",
        isValid: false,
      },
      aComment: {
        value: "",
        isValid: false,
      },
    },
    false
  );


const [showConfirmModal, setShowConfirmModal] = useState(false)

const showEditWarningHandler = (event) => {
event.preventDefault();
    const fetchComments = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/pages/getComment/${thisCommentId}`
        );
        setLoadedComment(responseData.comment);
        setEditingCommentId(responseData.comment.id)
      } catch (err) {}
    };
    fetchComments();
 setShowConfirmModal(true)
  }
 
 
const submitEditHandler = async event => {
event.preventDefault();
try{
await sendRequest(
  process.env.REACT_APP_BACKEND_URL +
    `/pages/updateComment/${editingCommentId}`,
  "PATCH",
  JSON.stringify({
    aComment: formState.inputs.aComment.value,
  }),
  {
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth.token,
  }
);
props.onEdit(props.id)
}catch(err){}
setShowConfirmModal(false);
}

const cancelDeleteHandler = () => {
  setShowConfirmModal(false)
}

const confirmDeleteHandler = async event => {
  event.preventDefault()
  try{
await sendRequest(
  process.env.REACT_APP_BACKEND_URL +
    `/pages/deleteComment/${editingCommentId}`,
  "DELETE",
  null,
  { Authorization: "Bearer " + auth.token }
);
props.onDelete(props.id);
}catch(err){}
  setShowConfirmModal(false);
}

const [showReplyModal, setShowReplyModal] = useState(false)

const showReplyFormHandler = (event) => {
  event.preventDefault();
  setShowReplyModal(true)
}

const cancelReplyHandler = (event) => {
  event.preventDefault();
  setShowReplyModal(false)
}


const confirmReplyHandler = async (event) => {
  event.preventDefault()
  try {
    await sendRequest(
      process.env.REACT_APP_BACKEND_URL + "/pages/reply",
      "POST",
      JSON.stringify({
        aReply: formState.inputs.aReply.value,
        commentId: thisCommentId,
        pageNumber: props.pageNumber,
      }),

      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      }
    );props.onEdit(props.id)
  } catch (err) {
  }
  setShowReplyModal(false)
}

return (
  <>
    <ErrorModal error={error} onClear={clearError} />
    {isLoading && (
      <DivCenter>
        <LoadingSpinner asOverlay />
      </DivCenter>
    )}
    {!isLoading && loadedComment && (
      <ModalContainer>
        <ModalForm
          onSubmit={submitEditHandler}
          show={showConfirmModal}
          onCancel={cancelDeleteHandler}
        >
          <ModalDiv>
            <InputDiv>
              {" "}
              <Input
                id="aComment"
                element="textarea"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Please enter a valid comment"
                onInput={inputHandler}
                value={loadedComment.aComment}
              />
              <ModalButtonDiv>
                <Button type="submit" disabled={formState.isValid}>
                  Submit
                </Button>
                <Button onClick={cancelDeleteHandler}>Cancel </Button>
                <Button onClick={confirmDeleteHandler}>Delete </Button>
              </ModalButtonDiv>
            </InputDiv>
          </ModalDiv>
        </ModalForm>
      </ModalContainer>
    )}
 <ErrorModal error={error} onClear={clearError} />
    {isLoading && (
      <DivCenter>
        <LoadingSpinner asOverlay />
      </DivCenter>
    )}
    {!isLoading &&  (
    <ModalContainer>
      <ModalForm
        onSubmit={confirmReplyHandler}
        show={showReplyModal}
        onCancel={cancelReplyHandler}
      >
        <ModalDiv>
          <InputDiv>
            <Input
              id="aReply"
              element="textarea"
              rows="3"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Please enter a valid comment"
              onInput={inputHandler}
            />
            <Button onClick={cancelReplyHandler}>Cancel </Button>
            <Button
              type="submit"
              disabled={formState.isValid}
            >
              Submit Reply
            </Button>
          </InputDiv>
        </ModalDiv>
      </ModalForm>
    </ModalContainer>
    )}
    <ContainerDiv>
      <CommentLi className="li__styles">
        <CommentDiv key={props.id}>
          <p className="bilbo__font">{props.creatorId}</p>
          <p className="raleway__font">{props.aComment}</p>
        </CommentDiv>
        <ButtonDiv>
         {auth.userName === "Harper Hall" && <Button inverse onClick={showReplyFormHandler}>
            Reply
          </Button>}
          {auth.userName === props.creatorId && <Button danger onClick={showEditWarningHandler}>
            Edit
          </Button> }
        </ButtonDiv> 
      </CommentLi>
    </ContainerDiv>
  </>
);
}

export default CommentItem
