import React from 'react'
import { useState, useContext } from 'react'
import { Link } from "react-router-dom";

import styled from 'styled-components';
import ModalForm from '../../shared/components/Modal/ModalForm'
import Button from '../../shared/components/FormElements/Button'
import { AuthContext } from '../../shared/context/auth-context'
import { useHttpClient } from "../../shared/hooks/http-hook";
const Mainpage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fefefe;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 1004;
`;
const NoClickDiv = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 1003;  
  pointer-event: none;
`;
const BackgroundImg = styled.img`
  width: 140vh;
  object-fit: contain;
  z-index: 1005;
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (max-width: 829px) {
    width: 80%;
    height: auto;
  }
  @media (max-width: 667px) {
    width: 80%;
    height: auto;
  }

  @media (max-width: 568px) {
    width: 80%;
    height: auto;
  }
`;

const ModalContainer = styled.div`
  width: 15vh;
  height: 5vh;
  display: flex;
  justify-content: center;
 
`;

const ModalDiv = styled.div`
  display: block;
  justify-content: center;
  position: relative;
  line-height: 1.8rem;
  font-size: 1.5rem;
  height: auto; 
  
`;

const ModalButtonDiv = styled.div`
  display: flex;
  padding: 1.5rem;
  
`;

const BoldP = styled.p `
font-size: 2.4rem;
padding: .8rem 0rem .8rem 0rem;
`;

const Splash = () => {

const auth = useContext(AuthContext)
 const { isLoading, error, sendRequest, clearError } = useHttpClient();

const [showContentWarning, setShowContentWarning] = useState(false)

const openContentWarningHandler = () => {
  setShowContentWarning(true)}

const closeContentWarningHandler = (event) => {
  event.preventDefault()
  setShowContentWarning(false);}
  
const ConfirmContentWarning = async event => {
event.preventDefault();
 
  try{
   const responseData = await sendRequest(
     process.env.REACT_APP_BACKEND_URL + "/users/"
   );
     
   auth.oldEnough(responseData.ageGate, responseData.ageGateToken)
}catch (err){}
  
}

return (
  <>
    <ModalContainer>
      <ModalForm
        show={showContentWarning}
        onCancel={closeContentWarningHandler}
        onSubmit={ConfirmContentWarning}
      >
        <ModalDiv>
          <p>
            This webcomic contains mature material including language, nudity,
            sexual situations, graphic violence, and drug use.
          </p>
          <BoldP>Are you 18 or older?</BoldP>
          <ModalButtonDiv>
            <Button type="submit">Yes </Button>
            <Button inverse onClick={closeContentWarningHandler}>
              No{" "}
            </Button>
          </ModalButtonDiv>
        </ModalDiv>
      </ModalForm>
    </ModalContainer>
    <NoClickDiv>
      <Mainpage onClick={openContentWarningHandler}>
        <BackgroundImg
          src={process.env.PUBLIC_URL + "/misc_img/Landing.jpeg"}
        ></BackgroundImg>
      </Mainpage>
    </NoClickDiv>
  </>
);
}

export default Splash
