import React from "react";
import styled from "styled-components";
import "./about.css";

const backimg = process.env.PUBLIC_URL + "/misc_img/aboutBackground.jpeg";

const Mainpage = styled.div`
  background-image: url(${backimg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1vh
`;



const CardDiv = styled.div`
  background: #00000094;
  z-index: 2;
  border-radius: 10px;
  width: 80vh;
  padding-top: 1rem;
  border: 2px solid #f1f1f1;
  position: absolute;
  top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 1445px) {
    width: 80%;
    margin-top: 80px;
  }

  @media (max-width: 1024px) {
    width: 80%;
    margin-top: 70px;
  }

  @media (max-width: 829px) {
    width: 100%;
    margin-top: 80px;
  }

  @media (max-width: 667px) {
    margin-top: 80px;
  }

  @media (max-width: 568px) {
    margin-top: 80px;
  }

  @media (max-width: 377px) {
    margin-top: 90px;
  }

  @media (max-width: 340px) {
    margin-top: 80px;
  }
`;

const CardContent = styled.p`
  padding: 2.3rem;
  color: #d6d6d6;
  font-size: 1.2rem;
  word-spacing: 0.4rem;
  text-shadow: 1px 2px #000000;
`;

const AuthImg = styled.img`
  width: 32vh;
  height: auto;
  padding: 5vh;
`;

const AboutTheAuthor = () => {
  return (
    <Mainpage> 
   
      <CardDiv>
      <AuthImg src= { process.env.PUBLIC_URL + "/misc_img/Harper.jpeg"}></AuthImg>
        <CardContent className="raleway__font">
          Hello dear readers! My name is Harper Hall (they/them), and I have
          been carrying the main cast and plot of Matriarch in my heart for the
          better part of twenty years. It has taken some time for me to start to
          go public with my work, but I have slowly been chipping away at this
          project since 2018 when I picked up my pencil and started drawing
          again following eight years of hiatus.
          <br /> <br />
           Much of what I write is what I see, and often question, in the world and events that take place
          around me. My protagonist is in so many ways my heartsong and has long
          been a brave soul I wished I could possess. Matriarch has provided a
          safe space as I have explored the constructs of gender as well sexual
          orientation, as writing is so often a haven to those who are LGBTQIA+.
          Without sharing too many spoilers, my protagonist was secure in who
          they were well before me both times I came out - first as bisexual in
          my early twenties, then as nonbinary in my late thirties.
          <br /> <br /> 
           I am a mother of two, food scientist by profession, beginner bodybuilder, and
          author/artist by night. I could not be who I am without the ever
          present allyship and unending support of my husband and life partner,
          Jeffrey. In my personal and professional life, I do what I can as an
          advocate for the LGBTQIA+ community.
        </CardContent>
        </CardDiv>
      </Mainpage>
  );
};

export default AboutTheAuthor;
