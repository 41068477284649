import { createContext } from 'react'


export const AuthContext = createContext({
  ageGateToken: null,
  isAgeGate: false,
  isLoggedIn: false, 
  userId: null,
  userName: null,
  token: null,
  login: () => {},
  logout: () => {},
  oldEnough: () => {}
});


