import React from "react";
import styled from "styled-components";
import './Charitem.css'

const Background = styled.body`
  background: #1f1f1f;
`;

const StyledLi = styled.ul`
  padding: 0% 4% 5% 4%;
  display: flex;


  @media (max-width: 731px) {
    width: auto;
    padding-top: 0% 4% 10% 4%;
  }
`;

const CenterText = styled.div`
  width: 50%;
  display: block;
  margin-right: 8%;
  @media (max-width: 1366px) {
   
  }
  @media (max-width: 1024px) {
    font-size: 1.8rem;
  }
  @media (max-width: 823px) {
   width: auto;
    margin-right: 0%;
  }
  @media (max-width: 731px) {
    
  }
  @media (max-width: 320px) {
    font-size: 1.2rem;
    width: auto;
  }
`;



const SideBySideDiv = styled.div`
  display: flex;
  justify-content: space-around;
  position: relative;
  margin: auto;
  overflow: hidden;
  width: 110vh;
  height: 65vh;
  background: #303030;
  box-shadow: 3px 3px 17px #000000;
  border-radius: 10px;
  align-items: center;


  @media (max-width: 823px) {
    flex-direction: column;
    width: auto;
    height: auto;
    padding: 2%;
    justify-content: center;
    text-align: center;
  }
`;
const ImgContainer = styled.img`
  width: 43vh;
  height: auto;
  margin-left: 5%;

  @media (max-width: 1024px) {
    margin-left: 0%;
    width: 38vh;
  }
  @media (max-width: 823px) {
    margin-left: 0%;
    width: 48vh;
  }
`;

const Name = styled.p`
  line-height: 280%;
  color: #d6d6d6;
  font-size: 2.8rem;
  white-space: nowrap;
  text-shadow: 1px 2px #000000;

  @media (max-width: 1366px) {
    font-size: 2rem;
  }
  @media (max-width: 1024px) {
    font-size: 2.8rem;
    white-space: normal;
    line-height: 120%;
  }
  @media (max-width: 823px) {
    font-size: 1.6rem;
  }
  @media (max-width: 731px) {
    font-size: 1.3rem;

    line-height: 100%;
  }
  @media (max-width: 320px) {
    font-size: 1.2rem;
  }
`;

const Bio = styled.p`
  color: #d6d6d6;
  font-size: 1.6rem;
  text-shadow: 1px 2px #000000;

@media (max-width: 1366px) {
    font-size: 1.8rem;
  }
  @media (max-width: 1024px) {
    font-size: 2.2rem;
  }
  @media (max-width: 823px) {
    font-size: 1.2rem;

  }
  @media (max-width: 731px) {
    font-size: 1.2rem;
  }
  @media (max-width: 320px) {
    font-size: 1rem;
  }
`;

const CharItem = props => {
  return (
    <Background>
      <StyledLi>
        <SideBySideDiv>
          <div>
            <ImgContainer
              src={props.image}
              alt={props.name}
            />
          </div>
          <CenterText>
            <Name className="bio__font">{props.name}</Name>
            <Bio className="info__font">{props.bio}</Bio>
          </CenterText>
        </SideBySideDiv>
      </StyledLi>
    </Background>
  );
};

export default CharItem;
