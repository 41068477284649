import React from 'react'
import CharItem from './CharItem'

const CharList = props => {
    return (
        <ul>
            {props.items.map(char => (
                <CharItem key= {char.id} image={char.src} name={char.name} bio={char.bio} />
            ))}
        </ul>
    )
}

export default CharList
