import React, { useEffect, useState } from 'react'
import {useHttpClient} from '../../shared/hooks/http-hook'
import CharList from '../components/CharList'
import styled from 'styled-components'
import ErrorModal from '../../shared/components/Modal/ErrorModal'
import LoadingSpinner from '../../shared/components/Modal/LoadingSpinner'
import { AiOutlineCopyrightCircle } from "react-icons/ai";

const DivCenter = styled.div `
display: flex;
width: 100%;
height: auto;
position: fixed;
margin-top: 50vh;
justify-content: center;
`
const PaddingTopCharList = styled.div `
padding-top: 0px;
`
const CopyWriteDiv = styled.div`
  text-align: center;
  color: white;
  padding: 1rem;
`;


const CastOfCharacters = () => {
  const {isLoading, error, sendRequest, clearError} = useHttpClient()
  const [loadedChars, setLoadedChars] = useState();

  useEffect(() => {
    const fetchChars = async () => {
      try{
   const responseData = await sendRequest(
     process.env.REACT_APP_BACKEND_URL + "/castofcharacters"
   );
     
   setLoadedChars(responseData.char)
}catch (err){}
  }
  fetchChars()  
  }, [sendRequest])
 
    return (
      <>
      <ErrorModal error={error} onClear={clearError}/>

      {isLoading && (
        <DivCenter>
          <LoadingSpinner />
        </DivCenter>
      )}
        <PaddingTopCharList>
          {!isLoading && loadedChars &&<CharList items={loadedChars} />}
        </PaddingTopCharList>
      
      <CopyWriteDiv>
          {" "}
          <AiOutlineCopyrightCircle color="white" /> 2019 Harper Hall
        </CopyWriteDiv>
      </>
    );
}

export default CastOfCharacters
