import React from 'react'
import styled from 'styled-components'
import { device } from "../../shared/device";
import "./about.css"


const backimg = process.env.PUBLIC_URL + "/misc_img/aboutBackground.jpeg";


const Mainpage = styled.div`
  background-image: url(${backimg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;


const CardDiv = styled.div`
  background: #00000094;
  z-index: 2;
  border-radius: 10px;
  width: 80vh;
  border: 2px solid #f1f1f1;
  margin-top: 2rem;

  @media (max-width: 1145px) {
    width: 80%;
    margin-top: 0px;
  }
  @media (max-width: 1024px) {
    width: 80%;
    margin-top: 50px;
  }
  @media (max-width: 829px) {
    width: 100%;
    margin-top: 100px;
  }
  @media (max-width: 667px) {
    margin-top: 150px;
  }
  @media (max-width: 653px) {
    margin-top: 230px;
  }
  @media (max-width: 640px) {
    margin-top: 150px;
  }
  @media (max-width: 568px) {
    margin-top: 240px;
  }
  @media (max-width: 527px) {
    margin-top: 80px;
  }

  @media (max-width: 377px) {
    margin-top: 120px;
  }

  @media (max-width: 340px) {
    margin-top: 170px;
  }
`;

const CardContent = styled.p`
  padding: 2.3rem;
  color: #d6d6d6;
  font-size: 1.8rem;
  word-spacing: 0.4rem;
  text-shadow: 1px 2px #000000;

  @media (max-width: 1366px) {
    font-size: 1.8rem;
  }
  @media (max-width: 1024px) {
    font-size: 1.8rem;
  }
  @media (max-width: 823px) {
    font-size: 1.6rem;
    padding: 1rem;
  }
  @media (max-width: 731px) {
    font-size: 1.5rem;
    padding: 1rem;
  }
  @media (max-width: 320px) {
    font-size: 1.2rem;
  }
`;
const Synopsis = () => {
    return (
      <Mainpage>
        <CardDiv>
          <CardContent className="raleway__font">
            Matriarch follows the life of royal Haldeina Muidemi ni Daarude
            through war and continental restructuring as they aim to pull their
            nation from long standing isolationism. As a teenager under male
            moniker Eidon, Muidemi is swept away in the political strife of the
            Belizios Empire. Estranged from their cousin, protected by their
            uncle, their agenda evolves as their eyes are opened to the larger
            world they intend to become a part of.
          </CardContent>
          <CardContent className="bilbo__font">
            Updates 15th and last day of each month.
          </CardContent>
        </CardDiv>
      </Mainpage>
    );
}

export default Synopsis
