import React from 'react'
import styled from "styled-components";
import "./about.css";

const backimg = process.env.PUBLIC_URL + "/misc_img/aboutBackground.jpeg";

const Mainpage = styled.div`
  background-image: url(${backimg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
`;

const Card = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 4vh;
 
`;

const CardDiv = styled.div`
  background: #00000094;
  border: 2px solid #f1f1f1;
  z-index: 2;
  border-radius: 10px;
  width: 110vh;
  padding-top: .5rem;

  @media (max-width: 1445px) {
    width: 80%;
    margin-top: 80px;
  }

  @media (max-width: 1024px) {
    width: 80%;
    margin-top: 70px;
  }

  @media (max-width: 829px) {
    width: 100%;
    margin-top: 80px;
  }

  @media (max-width: 667px) {
    margin-top: 80px;
  }

  @media (max-width: 568px) {
    margin-top: 80px;
  }
  
  @media (max-width: 377px) {
    margin-top: 90px;
  }

  @media (max-width: 340px) {
    margin-top: 80px;
  }

`;

const CardContent = styled.p`
  padding: 2.3rem;
  color: #d6d6d6;
  font-size: 1.2rem;
  ${'' /* text-shadow: 1px 2px #000000; */}
  word-spacing: .4rem;
  
 
`;

const Cardul = styled.ul`
  padding: 2rem 0rem 2rem 5rem;
  font-size: 1rem;
  color: #d6d6d6;
  word-spacing: 0.4rem;
`;

const Cardli = styled.li`
  
`;
const ContentWarning = () => {
  return (
    <Mainpage>
      <Card>
        <CardDiv>
          <CardContent className="raleway__font">
            What Matriarch is, and isn’t. <br />
            <br />
            This work is LGBTQIA+ with some fur. It is 'fantasy-drama’ and
            includes both political intrigue and romance as sub-genre material.
            Matriarch is also a war story, and though it depicts terror and
            destruction, it follows post war years of healing and recovery.
            There are socioeconomic, geopolitical, interracial relationships,
            and cultural structures, including gender and religion, depicted.
            Matriarch is a reflecting pool in many ways, and what is depicted
            herein is not endorsed or condoned by this author. It simply is
            described.
            <br />
            <br />
            Matriarch is a work of fiction. Names, characters, places, and
            events are either products of this author’s imagination or are used
            fictitiously. Any resemblance to actual events, locales, or persons
            - living or dead - is entirely coincidental.
          </CardContent>
          <CardContent className="raleway__font">
            <b>Content and sensitizing themes warning:</b>
          </CardContent>
          <Cardul>
            <Cardli className="raleway__font">
              <b>Abuse</b> including emotional abuse, verbal abuse, physical
              abuse, toxic relationship
            </Cardli>
            <Cardli className="raleway__font">
              <b>Assassination</b> including child
            </Cardli>
            <Cardli className="raleway__font">
              <b>Biracial experience</b> including insensitive language, body
              shaming, heritage/hereditary shaming
            </Cardli>
            <Cardli className="raleway__font">
              <b>Blood</b> including gore, graphic injury, animal injury, use in
              magic
            </Cardli>
            <Cardli className="raleway__font">
              <b>Body image</b> and insecurity
            </Cardli>
            <Cardli className="raleway__font">
              <b>Caste system</b> and classism{" "}
            </Cardli>
            <Cardli className="raleway__font">
              <b>Cannibalism</b>
            </Cardli>
            <Cardli className="raleway__font">
              <b>Death</b> including child
            </Cardli>
            <Cardli className="raleway__font">
              <b>Drug use</b> including tobacco, alcohol, opium
            </Cardli>
            <Cardli className="raleway__font">
              <b>Homophobia</b> including imprisonment and death penalty for
              homosexual behavior
            </Cardli>
            <Cardli className="raleway__font">
              <b>Illness</b> including mental, terminal
            </Cardli>
            <Cardli className="raleway__font">
              <b>Language</b> including foul, religious blasphemy{" "}
            </Cardli>
            <Cardli className="raleway__font">
              <b>Mental health</b> including anxiety, addiction, dysphoria,
              guilt, PTSD, panic attacks, psychotic break, violent outburst
            </Cardli>
            <Cardli className="raleway__font">
              <b>Neurodiversity</b>
            </Cardli>
            <Cardli className="raleway__font">
              <b>Nonbinary/Transgender experience</b> including dysphoria,
              misgendering, binding, packing (phallus)
            </Cardli>
            <Cardli className="raleway__font">
              <b>Non consensual marriage</b> including child bride, arranged
              marriage
            </Cardli>
            <Cardli className="raleway__font">
              <b>Polyamory</b> including poligamy
            </Cardli>
            <Cardli className="raleway__font">
              <b>Pregnancy</b> including fertility conversations, childbirth,
              death of infant, abortion, breast/chestfeeding
            </Cardli>
            <Cardli className="raleway__font">
              <b>Racism</b> including racial profiling, hate speech, genocide,
              systemic oppression
            </Cardli>
            <Cardli className="raleway__font">
              <b>Religion</b> and religious practices, concepts of sin
            </Cardli>
            <Cardli className="raleway__font">
              <b>Romance</b> including same sex, large age gap
            </Cardli>
            <Cardli className="raleway__font">
              Self harm including cutting, intoxication
            </Cardli>
            <Cardli className="raleway__font">
              <b>Sex</b> including sexual situations, heterosexuality,
              homosexuality, prositution
            </Cardli>
            <Cardli className="raleway__font">
              <b>Sexism</b> including misogyny, misogynistic language, extreme
              patriarchy, extreme feminism
            </Cardli>
            <Cardli className="raleway__font">
              <b>Sexual violence</b> depicted through survivor story (NOT
              described real-time, this author DOES NOT write rape scenes)
            </Cardli>
            <Cardli className="raleway__font">
              <b>Slavery</b> including abduction, human trafficking,
              confinement, sale
            </Cardli>
            <Cardli className="raleway__font">
              <b>Suicide</b> including attempted suicide
            </Cardli>
            <Cardli className="raleway__font">
              <b>War</b> including arson, execusions, imprisonment, starvation,
              refugee crisis, torture
            </Cardli>
          </Cardul>
        </CardDiv>
      </Card>
    </Mainpage>
  );
};

export default ContentWarning
