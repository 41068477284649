import React, {  useEffect, useState, useContext, setState } from 'react'
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import CommentItem from './CommentItem';
import Button from '../../../shared/components/FormElements/Button'
import Input from "../../../shared/components/FormElements/Input";
import { VALIDATOR_REQUIRE } from "../../../shared/components/util/validators";
import { useHttpClient } from '../../../shared/hooks/http-hook'
import ErrorModal from "../../../shared/components/Modal/ErrorModal";
import LoadingSpinner from "../../../shared/components/Modal/LoadingSpinner";
import { useForm } from "../../../shared/hooks/form-hook";
import ModalForm from "../../../shared/components/Modal/ModalForm";
import { AuthContext } from "../../../shared/context/auth-context";
import  ReplyItem  from './ReplyItem';



const DivCenter = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
`;


const CommentDiv = styled.div`
  display: block;
  background: #1f1f1f;
  flex-direction: column;
  margin-top: 10px;
  justify-content: top;
  ${'' /* border: solid 2px #111111;
  box-shadow: -5px 5px 10px #111111; */}
`;

const CommentUl = styled.ul`
  overflow: auto;
  ${'' /* width: 60vh;
  height: 35vh; */}
  list-style: none;
`;

const CommentList = () => {

  const auth = useContext(AuthContext)
  const [loadedCommentList, setLoadedCommentList] = useState();
  const { isLoading, error, sendRequest, clearError} =useHttpClient()

  const page = useParams().page;


  //fetch Comments in list
useEffect(() => {
const fetchComments = async () => {
  try {
    const responseData = await sendRequest( process.env.REACT_APP_BACKEND_URL + `/pages/getComments/${page}`
    );
      setLoadedCommentList(responseData.comment)
  }catch (err){} 
};
  fetchComments();
}, [sendRequest, page])

const onDeleteComment = deletedCommentId => {
  setLoadedCommentList(prevComment => prevComment.filter(comment=> comment.id !== deletedCommentId))
};
const onDeleteReply = async () => {
 try {
    const responseData = await sendRequest( process.env.REACT_APP_BACKEND_URL + `/pages/getComments/${page}`
    );
      setLoadedCommentList(responseData.comment)
  }catch (err){}
};
const onEditComment = async () => {
  try {
    const responseData = await sendRequest( process.env.REACT_APP_BACKEND_URL + `/pages/getComments/${page}`
    );
      setLoadedCommentList(responseData.comment)
  }catch (err){} 
};
const onEditReply = async () => {
  try {
    const responseData = await sendRequest(
      process.env.REACT_APP_BACKEND_URL + `/pages/getComments/${page}`
    );
    setLoadedCommentList(responseData.comment);
  } catch (err) {}
};

//NewComment adder
const [formState, inputHandler] = useForm(
  {
    comment: {
      value: "",
      isValid: false,
    },
  },
  false
);
const [showAddComment, setshowAddComment] = useState(false);
const showAddCommentHandler = () => {
  setshowAddComment(true);
};
const cancelAddCommentHandler = (event) => {
  event.preventDefault();
  setshowAddComment(false);
};
const confirmAddCommentHandler = async (event) => {
  event.preventDefault();
  console.log(auth.token)
  try {
    await sendRequest(
      process.env.REACT_APP_BACKEND_URL + `/pages/createComment/${page}`,
      "POST",
      JSON.stringify({
        aComment: formState.inputs.comment.value,
        pageNumber: page,
        replies: [],
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token
      },
      
    );
  } catch (err) {}
    setshowAddComment(false);
const fetchComments = async () => {
  try {
    const responseData = await sendRequest( process.env.REACT_APP_BACKEND_URL + `/pages/getComments/${page}`
    );
      setLoadedCommentList(responseData.comment)
  }catch (err){} 
};
  fetchComments();
};




    return (
      <>
        <ErrorModal error={error} onClear={clearError} />
        {isLoading && (
          <DivCenter>
            <LoadingSpinner />
          </DivCenter>
        )}
        {!isLoading && loadedCommentList && (
          <CommentDiv>
            <CommentUl>
              {loadedCommentList.map((comment, i) => (
                <div key={i}>
                  <CommentItem
                    pageNumber={comment.pageNumber}
                    key={comment.id}
                    id={comment.id}
                    aComment={comment.aComment}
                    creatorId={comment.creator}
                    onDelete={onDeleteComment}
                    onEdit={onEditComment}
                  />
                  {comment.replies.map((reply) => (
                    <ReplyItem
                      key={reply.id}
                      id={reply.id}
                      aReply={reply.aReply}
                      creator={reply.creator}
                      commentId={comment.id}
                      onDelete={onDeleteReply}
                      onEdit={onEditReply}
                    />
                  ))}
                </div>
              ))}
            </CommentUl>
            {!auth.isLoggedIn && (
              <Link to="/Login">
                <Button className="raleway__font">Login to comment.</Button>
              </Link>
            )}

            <ErrorModal error={error} onClear={clearError} />

            {auth.isLoggedIn && (
              <Button onClick={showAddCommentHandler}>Comment</Button>
            )}
            <ModalForm
              onSubmit={confirmAddCommentHandler}
              show={showAddComment}
              onCancel={cancelAddCommentHandler}
            >
              {isLoading && <LoadingSpinner asOverlay />}
              <div>
                <Input
                  id="comment"
                  element="input"
                  label="Add a Comment"
                  type="text"
                  placeholder="type it here"
                  validators={[VALIDATOR_REQUIRE()]}
                  errorText="Please enter a valid comment"
                  onInput={inputHandler}
                />
                <Button
                  type="submit"
                  onClick={confirmAddCommentHandler}
                  disabled={!formState.isValid}
                >
                  Add Comment
                </Button>
                <Button onClick={cancelAddCommentHandler}>Cancel</Button>
              </div>
            </ModalForm>
          </CommentDiv>
        )}
      </>
    );
}

export default CommentList
