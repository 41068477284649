import React, { useContext, useEffect } from "react";
import { useHttpClient } from "../../../hooks/http-hook";


import * as GiIcons from "react-icons/gi";
import * as GoIcons from "react-icons/go";
import * as RiIcons from "react-icons/ri";





export const SidebarData = [

  {
    title: "BookOne",
    path: "#",
    icon: <GiIcons.GiBookCover />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Cast of Characters",
        path: "/CastOfCharacters",
        icon: <GiIcons.GiScrollUnfurled />,
        cName: "sub-nav",
      },

      {
        title: "Chapter One",
        path: "/pages/1",
        icon: <GiIcons.GiScrollUnfurled />,
        cName: "sub-nav",
      },
      {
        title: "Chapter Two",
        path: "/pages/21",
        icon: <GiIcons.GiScrollUnfurled />,
        cName: "sub-nav",
      },
      {
        title: "Chapter Three",
        path: "/pages/43",
        icon: <GiIcons.GiScrollUnfurled />,
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "About",
    path: "#",
    icon: <GiIcons.GiTiedScroll />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Synopsis",
        path: "/Synopsis",
        icon: <GiIcons.GiCandleLight />,
        cName: "sub-nav",
      },
      {
        title: "Content Warning",
        path: "/ContentWarning",
        icon: <GiIcons.GiCandleLight />,
        cName: "sub-nav",
      },
      {
        title: " About the Author",
        path: "/AboutTheAuthor",
        icon: <GiIcons.GiCandleLight />,
        cName: "sub-nav",
      },
    ],
  },
 
  // {
  //   title: "Support",
  //   path: "/Donate",
  //   icon: <RiIcons.RiPatreonFill />,
  // },
];
