import React from "react";

const Donate = () => {
  return (
    <div className="home">
      <h1>Donate to meeee</h1>
    </div>
  );
};

export default Donate;
