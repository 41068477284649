import React, { useState, useCallback, useEffect } from 'react'
import { useHttpClient } from './shared/hooks/http-hook'

import "./App.css";
import Sidebar from "./shared/components/Sidebar/components/Sidebar";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import ComicPages from "./comic_book/pages/ComicPages";
import Splash from './main/pages/Splash'
import Home from './main/pages/Home'
import AboutTheAuthor from './about/pages/AboutTheAuthor'
import Donate from './donate/pages/Donate'
import Login from './login/pages/Login'
import Synopsis from "./about/pages/Synopsis";
import ContentWarning from "./about/pages/ContentWarning";
import CastOfCharacters from "./comic_book/pages/CastOfCharactors";
import { AuthContext } from "./shared/context/auth-context"
import { PageLengthContext } from './shared/context/page-context';
import LoginSingup from './login/pages/Login';


let logoutTimer;
let ageLogoutTimer;


const App = () => {
  const [ageGate, setIsAgeGate] = useState(false)
  const [userId, setUserId] = useState()
  const [userName, setUserName] = useState()
  const [token, setToken] = useState()
  const [ageGateToken, setAgeGateToken] = useState()
  const [tokenExperationDate, settokenExperationDate] = useState()
  const [ageTokenExperationDate, setageTokenExperationDate] = useState()


  const oldEnough = useCallback((ageGate, ageGateToken, ageExpirationDate) => {
    const ageTokenExpirationDate =
      ageExpirationDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 24);
      setageTokenExperationDate(ageTokenExperationDate)
    localStorage.setItem(
      "ageGate",
      JSON.stringify({
        ageGate: ageGate,
        ageGateToken: ageGateToken,
        ageExpiration: ageTokenExpirationDate.toDateString(),
      })
    ); 
    
    setIsAgeGate(ageGate)
    },
    [],
  )
useEffect(() => {
   const storedData =  JSON.parse(localStorage.getItem('ageGate'))
    if(storedData && storedData.ageGateToken && new Date(storedData.ageExpiration ) > new Date()) {
      oldEnough(
        storedData.ageGate,
        storedData.ageGateToken,
        new Date(storedData.ageExpiration)
      );
    }
  }, [oldEnough])

  const login = useCallback((uid, token, userName, expirationDate) => {
    setUserId(uid);
    setUserName(userName);
    setToken(token);
    const tokenExpirationDate =
      expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 2);
      settokenExperationDate(tokenExperationDate)
    localStorage.setItem(
      "userData",
      JSON.stringify({
        userId: uid,
        userName: userName,
        token: token,
        expiration: tokenExpirationDate.toDateString(),
      })
    );
  }, []);


 


  useEffect(() => {
  const storedData = JSON.parse(localStorage.getItem("userData"));
  if (storedData && storedData.token && new Date(storedData.expiration ) > new Date()
  ) {
    login(
      storedData.userId,
      storedData.userName,
      storedData.token,
      new Date(storedData.expiration)
    );
  }
}, [login]);

const logout = useCallback(() => {
    setToken(null);
    setUserId(null);
    settokenExperationDate(null)
    localStorage.removeItem('userData')
  }, []);
 
  useEffect(() => {
   if (ageGateToken && tokenExperationDate) {
     const remainingTime = ageTokenExperationDate.getTime() - new Date().getTime()
    ageLogoutTimer =  setTimeout(logout, remainingTime )
   }else {
     clearTimeout(ageLogoutTimer);
   }
  }, [ageGateToken, logout, tokenExperationDate])

  useEffect(() => {
    if (token && tokenExperationDate) {
      const remainingTime =
        tokenExperationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExperationDate]);

let routes;

if (ageGate){
  routes = (
    <Switch>
      <Route path="/home" exact component={Home} />
      <Route path="/castofcharacters" exact component={CastOfCharacters} />
      <Route path="/pages/:page" exact component={ComicPages} />
      <Route path="/pages/1" exact component={ComicPages} />
      <Route path="/abouttheauthor" exact component={AboutTheAuthor} />
      <Route path="/synopsis" exact component={Synopsis} />
      <Route path="/contentwarning" exact component={ContentWarning} />
      <Route path="/donate" exact component={Donate} />
      <Route path="/login" exact component={LoginSingup} />
      {/* <Route path="/update" exact component={UpdateComic} /> */}
      <Redirect to="/home" />
    </Switch>
  );
}
else{
  routes = (
    <Switch>
      <Route path="/" exact component={Splash} />
      <Route path="/home" exact component={Home} />
      <Route path="/castofcharacters" exact component={CastOfCharacters} />
      <Route path="/page/:page" exact component={ComicPages} />
      <Route path="/page/1" exact component={ComicPages} />
      <Route path="/abouttheauthor" exact component={AboutTheAuthor} />
      <Route path="/synopsis" exact component={Synopsis} />
      <Route path="/contentwarning" exact component={ContentWarning} />
      <Route path="/donate" exact component={Donate} />
      <Route path="/login" exact component={LoginSingup} />
      <Redirect to="/" />
    </Switch>
  );
}

  return (
    <AuthContext.Provider value={{
      ageGate: ageGate, 
      isLoggedIn: !!token,
      token: token,
      userId: userId,
      userName: userName,
        login: login, 
        logout: logout,
        oldEnough: oldEnough 
       }}>
    
      <Router>
        <Sidebar />
        {routes}
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
