import React, { useState, useEffect } from 'react'
import { Link, useParams } from "react-router-dom";

import { useHttpClient } from '../../shared/hooks/http-hook'
import ErrorModal from '../../shared/components/Modal/ErrorModal'
import LoadingSpinner from '../../shared/components/Modal/LoadingSpinner'
import styled from "styled-components"
import {GrCaretNext} from 'react-icons/gr'
import { GrCaretPrevious, GrClose } from "react-icons/gr";
import Modal from '../../shared/components/Modal/Modal'



 
const StyleIcon = styled.div`
  font-size: 40px;
  padding: 8px 5px 0px 8px;
  background: #cccccc;
  border-radius: 30px 0px 30px 0px;
  margin: 25px;
  box-shadow: 3px 3px 4px 4px #000000;

  @media (max-width: 823px) {
    font-size: 30px;
    margin: 10px;
  }
`;

const StyleIconNext = styled.div`
  font-size: 40px;
  padding: 8px 5px 0px 8px;
  background: #cccccc;
  border-radius: 30px 0px 30px 0px;
  margin: 25px;
  box-shadow: 3px 3px 4px 4px #000000;
  order: 3;
  @media (max-width: 823px) {
    font-size: 35px;
    margin-top: 20px;
    order:3;
    
  }
`;

const StyleIconPrev = styled.div`
  font-size: 40px;
  padding: 8px 5px 0px 8px;
  background: #cccccc;
  border-radius: 30px 0px 30px 0px;
  margin: 25px;
  box-shadow: 3px 3px 4px 4px #000000;
  order: 1;
  @media (max-width: 823px) {
    font-size: 35px;
    margin-top: 20px;
    order:2
  }
`;
const ImgDiv = styled.div`
  background: #1f1f1f;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  @media (max-width: 823px) {
    flex-wrap: wrap;
    justify-content: space-around;
  }

  
`;

const ImgContainer = styled.img`
  max-width: 780px;
  height: auto;
  margin-top: 30px;
  box-shadow: 3px 3px 17px #000000;
  border-radius: 10px;
  order: 2;
  cursor: pointer;

  @media (max-width: 823px) {
    max-width: 100%;
    margin-top: 10px;
    order: 1;
  }
`;

const ModalDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  /* spacing as needed */
  padding: 60vh 12vh 5vh 12vh;
  /* let it scroll */
  overflow: auto;

  @media (max-width: 1366px) {
    max-width: 150%;
    margin-top: 0px;
    position: fixed;
  }
`;


const ModalImgContainer = styled.img`
  justify-content: center;
  align-items: center;
  width: 1400px;
  max-width: 100%;
  height: auto;

  @media (max-width:1366px) {
   position: absolute;
   left: 0px;
   top: 0px;
   max-width: 150%;
    
  }
`; 
const ModalButtonClose = styled.button`
  font-size: 3rem;
  position: absolute;
  margin-right: 82rem;
  margin-bottom: 80rem;
  cursor: pointer;
  padding: 1rem 1rem 0rem 1rem;

  @media (max-width: 1366px) {
    position: absolute;
    left: 0px;
    top: 0px;
    max-width: 150%;
    z-index: 1004;
    font-size: 2rem;
  }
`;

const DivCenter = styled.div `
display: flex;
width: 100%;
height: auto;
position: fixed;
margin-top: 50vh;
justify-content: center;
`


const PageDisplay = () => {
  
  const thisPage = useParams().page;
  const pageInt = parseInt(thisPage);
 const [loadedPage, setLoadedPage] =useState()
  const { isLoading, error, sendRequest, clearError } = useHttpClient();




useEffect(() => {
 const fetchPage = async () => {
  try{
    const responseData = await sendRequest(
      process.env.REACT_APP_BACKEND_URL + "/pages"
    );
    setLoadedPage(responseData.pages)
  }catch (err){}
 }
 fetchPage();
}, [sendRequest])

const [showImg, setShowImg] = useState(false)

const openImgHandler = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/pages"
      );
      setLoadedPage(responseData.pages);
    } catch (err) {}setShowImg(true)
  };
 
const closeImgHandler = () => setShowImg(false)



  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <DivCenter>
          <LoadingSpinner />
        </DivCenter>
      )}
      {!isLoading && loadedPage && (
      <Modal show={showImg} onCancel={closeImgHandler}>
        <ModalDiv>
          <ModalButtonClose onClick={closeImgHandler}>
            {" "}
            <GrClose />{" "}
          </ModalButtonClose>
          <StyleIcon>
            <Link to={`/pages/${thisPage === "1" ? "1" : pageInt - 1}`}>
              <GrCaretPrevious color="white" />
            </Link>
          </StyleIcon>
          <ModalImgContainer src={loadedPage[pageInt - 1].src} />
          <StyleIcon>
            <Link to={`/pages/${parseInt(thisPage) + 1}`}>
              <GrCaretNext />
            </Link>
          </StyleIcon>
        </ModalDiv>
      </Modal> )}
      
      {!isLoading && loadedPage && (
        <div>
          <ImgDiv>
            <ImgContainer
              src={loadedPage[pageInt - 1].src}
              onClick={openImgHandler}
            />{" "}
            <StyleIconPrev>
              <Link to={`/pages/${thisPage === "1" ? "1" : pageInt - 1}`}>
                <GrCaretPrevious />
              </Link>
            </StyleIconPrev>
            <StyleIconNext>
              <Link
                to={`/pages/${
                  pageInt === loadedPage.length ? thisPage : pageInt + 1
                }`}
              >
                <GrCaretNext />
              </Link>
            </StyleIconNext>
          </ImgDiv>
        </div>
      )}
    </>
  );
}

export default PageDisplay
