import React from 'react'
import styled from 'styled-components';
import "./Home.css"



const backimg =  process.env.PUBLIC_URL +
'/misc_img/Homepage.png'

const Mainpage = styled.div`
  background-image: url( ${backimg} );
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; 
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  

  
`;
const MainText = styled.div`
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.6); /* Black w/opacity/see-through */
  color: white;
  border: 2px solid #f1f1f1;
  border-radius: 10px;
  z-index: 2;
  width: 60%;
  padding: 3rem;
  text-align: center;
  line-height: 3rem;
  word-spacing: 0rem;
  font-size: 1.8rem;
  text-shadow: -3px -2px 4px black;

  @media (max-width: 1445px) {
    width: 80%;
    margin-top: 80px;
  }

  @media (max-width: 1024px) {
    width: 90%;
    margin-top: 70px;
    font-size: 2.5rem;
  }

  @media (max-width: 829px) {
    font-size: 1.6rem;
    width: 90%;
    margin-top: 90px;
    padding: 0px;
  }

  @media (max-width: 667px) {
    font-size: 1.6rem;
    line-height: 2.5rem;
    margin-top: 35%;
    padding: 0px;
  }

  @media (max-width: 568px) {
    font-size: 1.3rem;
    margin-top: 45%;
    padding: 0px;
  }
  @media (max-width: 540px) {
    font-size: 1.3rem;
    margin-top: 5%;
    padding: 0px;
  }
  @media (max-width: 377px) {
    font-size: 1.3rem;
    margin-top: 40px;
    padding: 0px;
  }

  @media (max-width: 340px) {
    font-size: 1.3rem;
    margin-top: 45%;
  }
  @media (max-width: 280px) {
    font-size: 1.3rem;
    line-height: 2rem;
    margin-top: 5%;
  }
`;

const Powerh1 = styled.div`
  font-size: 3rem;
  padding: 2.2rem 0rem;
  text-shadow: -3px -2px 4px black;
 


  @media (max-width: 1024px) {
    font-size: 4rem;
  }
  @media (max-width: 829px) {
    font-size: 2.6rem;}
   @media (max-width: 667px) {
    font-size: 2.6rem;}

  @media (max-width: 568px) {
    font-size: 2.4rem;
  }
  @media (max-width: 377px) {
    font-size: 2rem;
  }
  @media (max-width: 340px) {
    padding: 10px 0px;
    font-size: 2.2rem;
  }
`;

const Home = () => {
    return (
      <Mainpage>
        <MainText>
          <p className="maintext">
            Muidemi's life had been disrupted more than once by the agenda of
            the larger world. As the twelve year old lay wounded on the forest
            floor, a shift in their psyche spurs them to become the catalyst of
            change.{" "}
          </p>
          <Powerh1 className="thep">
            The power that will restructure a continent awakens.
          </Powerh1>
        </MainText>
      </Mainpage>
    );
}

export default Home

