import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router'
import { useForm } from "../../shared/hooks/form-hook";
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_MINLENGTH,
  VALIDATOR_EMAIL,
} from "../../shared/components/util/validators";
import { useHttpClient } from '../../shared/hooks/http-hook'
import { AuthContext } from '../../shared/context/auth-context';
import Input from "../../shared/components/FormElements/Input";
import ErrorModal from '../../shared/components/Modal/ErrorModal'
import LoadingSpinner from '../../shared/components/Modal/LoadingSpinner'
import styled from "styled-components";
import Button from '../../shared/components/FormElements/Button';

const ContentDiv = styled.div`
  margin: auto;
  background: #ebebeb;
  box-shadow: 0vh 0vh 5vh #000000;
  border-radius: 5vh;
  padding: 2vh 5vh 8vh 5vh;
  width: 30%;
  height: auto;
  text-align: center;
  line-height: 1.5rem;
  font-size: 1.2rem;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1024px) {
    font-size: 1.8rem;
  }
  @media (max-width: 823px) {
    width: auto;
    margin-right: 0%;
  }
  @media (max-width: 731px) {
  }
  @media (max-width: 320px) {
    font-size: 1.2rem;
    width: auto;
  }
`;
const InputForm = styled.form`
  padding: 0.5rem;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1024px) {
    font-size: 1.8rem;
  }
  @media (max-width: 823px) {
    width: auto;
    margin-right: 0%;
  }
  @media (max-width: 731px) {
    font-size: 1.4rem;
  }
  @media (max-width: 320px) {
    font-size: 1.4rem;
    width: auto;
  }
`;

const SinUph1 = styled.h1`
  padding: 2rem 0rem 3rem 0rem;
  line-height: 2rem;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1024px) {
    font-size: 1.8rem;
  }
  @media (max-width: 823px) {
    width: auto;
    margin-right: 0%;
  }
  @media (max-width: 731px) {
  }
  @media (max-width: 320px) {
    font-size: 1.2rem;
    width: auto;
  }
`;
const ButtonDiv = styled.div`
  padding: 0.5rem;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1024px) {
    font-size: 1.8rem;
  }
  @media (max-width: 823px) {
    width: auto;
    margin-right: 0%;
  }
  @media (max-width: 731px) {
    font-size: 1.4rem;
  }
  @media (max-width: 320px) {
    font-size: 1.4rem;
    width: auto;
  }
`;
const LoginSingup = () => {
  const history = useHistory()
  function goBackHandler() {
    history.goBack()
  }
  const auth = useContext(AuthContext);
  const [isLoginMode, setIsLoginMode] = useState(false)
  const {isLoading, error, sendRequest, clearError } = useHttpClient();

  const [formState, inputHandler, setFormData] = useForm(
    {
      userName: {
        value: "",
        isValid: false,
      }, 
     
      password: {
        value: "",
        isValid: false,
      },

    },
    false
  );

  const switchModeHandler =  () => {
    if(isLoginMode){
      setFormData({
        ...formState.inputs,
        userName: undefined
      }, 
      formState.inputs.email.isValid && formState.inputs.password.isValid)
    } else{
      setFormData({
        ...formState.inputs,
      userName: {
        value: '',
        isValid: false
            }
      }, 
      false
      )
    }
    setIsLoginMode(prevMode => !prevMode)
  }

const LoginHandler = async event => {
  event.preventDefault()

  if (isLoginMode){
    try {
        const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/users/login",
        'POST',
        JSON.stringify({
          userName: formState.inputs.userName.value,
          password: formState.inputs.password.value,
        }),
        {
        "Content-Type": "application/json",
        }
      );
      auth.login( responseData.userId,responseData.token, responseData.userName  );
    
}catch (err) {}
 
 }else{
   try {
       const responseData = await sendRequest(
         process.env.REACT_APP_BACKEND_URL + "/users/signup",
         "POST",
         JSON.stringify({
           userName: formState.inputs.userName.value,
           email: formState.inputs.email.value,
           password: formState.inputs.password.value,
         }),
         {
           "Content-Type": "application/json",
         }
       );
      auth.login(
        responseData.userId,
        responseData.token,
        responseData.userName
      );
}catch (err) {}
}

}

 return (
   <>
     <ErrorModal error={error} onClear={clearError} />
     <ContentDiv>
       {isLoading && <LoadingSpinner asOverlay />}
       <InputForm onSubmit={LoginHandler}>
         <SinUph1>{isLoginMode ? "Login" : "Register new user"}</SinUph1>

         <Input
           element="input"
           id="userName"
           type="text"
           label="User name"
           validators={[VALIDATOR_REQUIRE()]}
           errorText="Please enter a user name."
           onInput={inputHandler}
         />
         {!isLoginMode && (
           <Input
             id="email"
             element="input"
             type="email"
             label="E-mail"
             placeholder="email@something.com"
             validators={[VALIDATOR_EMAIL()]}
             errorText="Please enter a valid email address."
             onInput={inputHandler}
           />
         )}
         <Input
           id="password"
           element="input"
           type="password"
           label="Password"
           validators={[VALIDATOR_MINLENGTH(6)]}
           errorText="Please enter a  Password, at least 6 characters."
           onInput={inputHandler}
         />

         <Button type="submit">{isLoginMode ? "Login" : "Sign Up"}</Button>
       </InputForm>

       <ButtonDiv>
         <Button onClick={switchModeHandler}>
           {!isLoginMode ? "Switch to Login" : "Switch Sign Up"}
         </Button>
       </ButtonDiv>
       <Button onClick={goBackHandler}>
           Return to Previous Page
         </Button>
     </ContentDiv>
   </>
 );
}


export default LoginSingup;
